.faq-page {
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f8fbff;
}

.faq-header {
  text-align: center;
  margin-bottom: 60px;
  margin-top: 60px;
  padding: 0 20px;
}

.faq-header h1 {
  font-size: 3.5rem;
  color: #333333;
  line-height: 1.2;
}

.highlight {
  color: #33a9ff;
}

.faq-list {
  max-width: 1000px;
  margin: 0 auto;
}

.faq-item {
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.faq-question {
  width: 100%;
  text-align: left;
  padding: 20px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #38b6ff;
  font-size: 18px;
  transition: all 0.3s ease;
  gap: 50px;
}

.question-text {
  flex: 1;
}

.toggle-icon {
  font-size: 1.5rem;
  margin-left: 20px;
  color: #33a9ff;
}

.faq-answer {
  padding: 0 0 25px;
  color: #666;
  line-height: 1.6;
  text-align: left;
  margin-left: 20px;
}

.faq-item.active .toggle-icon {
  transform: rotate(45deg);
}

.plus-icon {
  font-size: 24px;
  color: #38b6ff;
  margin-left: auto;
}

/* Responsive Design */
@media (max-width: 768px) {
  .faq-page {
    padding: 40px 24px;
  }

  .faq-header {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .faq-header h1 {
    font-size: 2rem;
    line-height: 1.3;
  }

  .faq-list {
    padding: 0 10px;
  }

  .faq-question {
    font-size: 1rem;
    padding: 20px 15px;
    gap: 20px;
  }

  .faq-answer {
    padding: 0 15px 20px;
    margin-left: 0;
    font-size: 0.95rem;
  }

  .toggle-icon {
    font-size: 1.2rem;
    margin-left: 10px;
  }
}

/* Small Mobile Devices */
@media (max-width: 480px) {
  .faq-page {
    padding: 30px 16px;
  }

  .faq-header {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .faq-header h1 {
    font-size: 1.75rem;
  }

  .faq-list {
    padding: 0 5px;
  }

  .faq-question {
    padding: 15px 10px;
    gap: 15px;
  }

  .faq-answer {
    padding: 0 10px 15px;
  }
} 
