.contact-page {
  padding-top: 0px;
}

.contact-hero {
 
  color: #333;
  text-align: center;
  padding: 80px 20px;
}

.contact-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
}

.contact-hero p {
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
}

.contact-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px;
  display: flex;
  gap: 60px;
}

.contact-info {
  flex: 1;
  padding: 30px;
}

.info-item {
  margin-bottom: 40px;
}

.info-item h3 {
  color: #33a9ff;
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.info-item p {
  color: #666;
  font-size: 1.1rem;
}

.contact-form-container {
  flex: 2;
}

.contact-form {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #33a9ff;
}

.submit-btn {
  background-color: #33a9ff;
  color: white;
  border: none;
  padding: 15px 40px;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.submit-btn:hover {
  background-color: #2b90d9;
}

/* Responsive Design */
@media (max-width: 968px) {
  .contact-content {
    flex-direction: column;
  }

  .contact-hero h1 {
    font-size: 2.5rem;
  }

  .contact-form {
    padding: 20px;
  }
} 