.about-page {
  padding-top: 60px;
  background-color: #f8fbff;
  min-height: 100vh;
}

.about-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 60px 20px;
  display: flex;
  gap: 60px;
  align-items: center;
}

.about-left {
  flex: 1;
  padding-right: 40px;
}

.about-left h1 {
  font-size: 3.5rem;
  color: #333;
  line-height: 1.2;
  margin-bottom: 60px;
}

.highlight {
  color: #33a9ff;
}

.who-we-are,
.our-mission {
  margin-bottom: 40px;
}

.who-we-are h2,
.our-mission h2 {
  font-size: 2.5rem;
  color: #33a9ff;
  margin-bottom: 20px;
}

.who-we-are p,
.our-mission p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
}

.about-right {
  flex: 1;
}

.about-image {
  width: 100%;
  height: auto;
  max-width: 600px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .about-content {
    flex-direction: column;
    text-align: center;
  }

  .about-left {
    padding-right: 0;
  }

  .about-left h1 {
    font-size: 2.5rem;
    margin-bottom: 40px;
  }

  .who-we-are h2,
  .our-mission h2 {
    font-size: 2rem;
  }

  .about-image {
    margin-top: 40px;
  }
} 