*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

#root {
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  padding-top: 90px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #38b6ff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #38b6ff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo img {
  height: 70px;
  width: auto;
}

.announcement-bar {
  background-color: #38b6ff;
  color: white;
  text-align: center;
  padding: 8px;
  font-size: 14px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 200px;
  background-color: #38b6ff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
}

.nav-links {
  display: flex;
  gap: 30px;
  align-items: center;
}

.nav-links a {
  text-decoration: none;
  color: #ffffff;
  padding: 10px 15px;
  transition: color 0.3s ease;
  font-size: 18px;
}

.nav-links a:hover {
  color: #38b6ff;
}

.nav-links a.contact-btn {
  background-color: #ffffff;
  color: #38b6ff;
  padding: 10px 20px;
  border-radius: 6px;
}

.nav-links a.contact-btn:hover {
  background-color: #ffffff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar {
    padding: 10px 20px;
    position: fixed;
  }
  
  .nav-links {
    display: none; /* You might want to add a mobile menu */
    top: 0;
    padding-top: 80px;
  }

  .hamburger {
    z-index: 1001;
  }
}

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 80px;
  background-color: white;
  gap: 40px;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
}

.hero-content {
  flex: 1;
  max-width: 600px;
  padding-left: 100px;
}

.hero-content h1 {
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 24px;
  color: #000;
}

.hero-content p {
  font-size: 1.25rem;
  color: #666;
  line-height: 1.6;
}

.hero-image {
  flex: 1;
  max-width: 700px;
  padding-right: 100px;
}

.hero-image img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .hero {
    padding: 40px;
  }
  
  .hero-content h1 {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
    padding: 30px 20px;
  }

  .hero-content h1 {
    font-size: 2.5rem;
  }
}

.how-it-works {
  padding: 80px 20px;
  text-align: center;
}

.how-it-works h2 {
  font-size: 3.5rem;
  color: #38b6ff;
  margin-bottom: 60px;
}

.steps-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.step {
  flex: 1;
  max-width: 350px;
}

.step-image {
  width: 200px;
  height: auto;
  margin-bottom: 24px;
}

.step h3 {
  font-size: 2rem;
  margin-bottom: 16px;
  color: #333;
}

.step p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
}

/* Responsive Design */
@media (max-width: 968px) {
  .steps-container {
    flex-direction: column;
    align-items: center;
  }

  .step {
    margin-bottom: 40px;
  }
}

.fresh-meals {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px;
  background-color: #f8fbff;
  gap: 60px;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
}

.fresh-meals-content {
  flex: 1;
  max-width: 500px;
  padding-left: 200px;
}

.fresh-meals-content h2 {
  font-size: 3.5rem;
  color: #33a9ff;
  margin-bottom: 24px;
}

.fresh-meals-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
}

.fresh-meals-image {
  flex: 1;
  max-width: 600px;
  padding-right: 200px;
}

.fresh-meals-image img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 968px) {
  .fresh-meals {
    flex-direction: column;
    padding: 40px 20px;
    text-align: center;
  }

  .fresh-meals-content h2 {
    font-size: 2.5rem;
  }
}

.why-chef-prep {
  padding: 80px 20px;
  text-align: center;
}

.why-chef-prep h2 {
  font-size: 3.5rem;
  color: #38b6ff;
  margin-bottom: 60px;
}

.benefits-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.benefit {
  flex: 1;
  max-width: 350px;
}

.benefit-icon {
  width: 125px;
  height: 125px;
  margin-bottom: 24px;
}

.benefit h3 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 16px;
}

.benefit p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
}

/* Responsive Design */
@media (max-width: 968px) {
  .benefits-container {
    flex-direction: column;
    align-items: center;
  }

  .benefit {
    margin-bottom: 40px;
  }

  .why-chef-prep h2 {
    font-size: 2.5rem;
  }
}

.join-now {
  display: flex;
  background: linear-gradient(to right, #ffffff 50%, #1a1a1a 50%);
  padding: 80px 20px;
  min-height: 600px;
  border-style: solid;
  border-color: #000000;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
}

.join-now-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.join-now-left img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.join-now-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 60px;
}

.join-now-right h2 {
  font-size: 4rem;
  color: white;
  margin-bottom: 30px;
}

.download-btn {
  background-color: #38b6ff;
  color: white;
  border: none;
  padding: 15px 40px;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-btn:hover {
  background-color: #38b6ff;
}

/* Responsive Design */
@media (max-width: 968px) {
  .join-now {
    flex-direction: column;
    background: linear-gradient(to bottom, #33a9ff 50%, #1a1a1a 50%);
  }

  .join-now-right {
    align-items: center;
    padding-left: 0;
    padding-top: 40px;
  }

  .join-now-right h2 {
    font-size: 3rem;
  }
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
}

.hamburger span {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 2px 0;
  transition: 0.3s;
}

/* Updated Mobile Styles */
@media (max-width: 768px) {
  .navbar {
    padding: 10px 20px;
  }

  .hamburger {
    display: flex;
    z-index: 1000;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 50%;
    background-color: #38b6ff;
    padding: 80px 20px;
    transition: 0.3s ease-in-out;
    z-index: 1000;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .nav-links.active {
    right: 0;
  }

  .nav-links a {
    margin: 10px 0;
    font-size: 20px;
    color: white;
  }

  .nav-links a:hover {
    color: rgba(255, 255, 255, 0.8);
  }

  .nav-links a.contact-btn {
    background-color: white;
    color: #38b6ff;
  }

  /* Hero Section */
  .hero {
    padding: 40px 20px;
  }

  .hero-content {
    padding-left: 0;
  }

  .hero-image {
    padding-right: 0;
  }

  /* Fresh Meals Section */
  .fresh-meals {
    padding: 40px 20px;
  }

  .fresh-meals-content {
    padding-left: 0;
  }

  .fresh-meals-image {
    padding-right: 0;
  }

  /* Benefits Section */
  .benefits-container {
    padding: 0 20px;
  }

  /* Join Now Section */
  .join-now {
    padding: 40px 20px;
  }

  .join-now-right {
    padding: 40px 20px;
    text-align: center;
  }

  /* Prevent body scroll when menu is open */
  body.menu-open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }

  /* Add overlay when menu is open */
  .nav-links::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }

  .nav-links.active::before {
    opacity: 1;
    pointer-events: auto;
  }

  /* Ensure menu items are visible */
  .nav-links a {
    opacity: 1;
    visibility: visible;
    color: white;
    width: 100%;
    text-align: center;
  }
}

/* Additional responsive adjustments */
@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2rem;
  }

  .fresh-meals-content h2,
  .why-chef-prep h2 {
    font-size: 2rem;
  }

  .benefit h3 {
    font-size: 1.5rem;
  }

  .join-now-right h2 {
    font-size: 2.5rem;
  }
}

/* Enhanced Mobile Responsive Styles */
@media (max-width: 768px) {
  .logo img {
    height: 50px;
  }

  .navbar {
    padding: 8px 16px;
  }

  .hero-content {
    padding: 20px;
    max-width: 100%;
  }

  .hero-content h1 {
    font-size: 2.25rem;
    margin-bottom: 16px;
  }

  .hero-content p {
    font-size: 1.1rem;
  }

  .how-it-works {
    padding: 40px 16px;
  }

  .how-it-works h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
  }

  .step-image {
    width: 150px;
  }

  .step h3 {
    font-size: 1.75rem;
  }

  .step p {
    font-size: 1.1rem;
  }

  .fresh-meals-content,
  .fresh-meals-image {
    padding: 0 20px;
    max-width: 100%;
  }

  .benefits-container {
    padding: 0 16px;
  }

  .benefit-icon {
    width: 100px;
    height: 100px;
  }
}

/* Enhanced Small Mobile Styles */
@media (max-width: 480px) {
  .logo img {
    height: 40px;
  }

  .announcement-bar {
    font-size: 12px;
    padding: 6px;
  }

  .navbar {
    padding: 8px 12px;
  }

  .nav-links.active {
    width: 65%;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .how-it-works h2,
  .fresh-meals-content h2,
  .why-chef-prep h2 {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .step {
    padding: 0 12px;
  }

  .step h3 {
    font-size: 1.5rem;
  }

  .step p,
  .fresh-meals-content p,
  .benefit p {
    font-size: 1rem;
    line-height: 1.5;
  }

  .join-now {
    min-height: auto;
    padding: 30px 16px;
  }

  .join-now-right h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .download-btn {
    padding: 12px 30px;
    font-size: 1.1rem;
  }

  .benefit-icon {
    width: 80px;
    height: 80px;
  }

  /* Improve hamburger menu touch target */
  .hamburger {
    padding: 12px;
  }

  .hamburger span {
    width: 22px;
    margin: 3px 0;
  }
}

/* Add better touch targets for mobile navigation */
@media (max-width: 768px) {
  .nav-links a {
    padding: 12px 16px;
    width: 100%;
    text-align: center;
  }

  .nav-links a.contact-btn {
    margin: 10px 0;
    width: auto;
  }
}

/* Ensure the App container doesn't overflow */
.App {
  position: relative;
  overflow-x: hidden;
  width: 100%;
}

/* Remove duplicate width declarations */
.nav-links {
  width: auto;
}

/* Add for small screens */
@media (max-width: 480px) {
  .nav-links {
    width: 65%;
  }
}
