.footer {
  background-color: #333;
  color: white;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem;
}

.footer-section {
  flex: 1;
  min-width: 250px;
}

.footer-section h4 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.footer-section a,
.footer-section p {
  display: block;
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  
}

.footer-section a:hover {
  text-decoration: underline;
}

.social-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-bottom {
  margin-top: 2rem;
  text-align: center;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 1rem;
  }

  .footer-section {
    min-width: 100%;
    text-align: center;
  }

  .social-links {
    align-items: center;
  }

  .footer-section h4 {
    font-size: 1.1rem;
  }

  .footer-section a,
  .footer-section p {
    font-size: 0.9rem;
  }

  .footer-bottom {
    width: 100%;
    padding: 1rem 16px;
    margin: 0;
  }
}

/* Small Mobile Devices */
@media screen and (max-width: 480px) {
  .footer-container {
    padding: 1.5rem 1rem;
  }

  .footer-section {
    padding: 0;
  }

  .footer-bottom {
    padding: 1rem 12px;
  }
} 
