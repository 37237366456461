.legal-page {
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.legal-header {
  text-align: center;
  margin-bottom: 60px;
  margin-top: 60px;
}

.legal-header h1 {
  font-size: 3.5rem;
  color: #333333;
  line-height: 1.2;
}

.legal-content {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  line-height: 1.6;
  color: #333;
  padding: 0 20px;
}

.terms-section,
.privacy-section {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.legal-text {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

h2 {
  color: #38b6ff;
  font-size: 28px;
  margin-bottom: 25px;
}

h3 {
  color: #2c3e50;
  font-size: 20px;
  margin-bottom: 10px;
}

p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Tablet Devices */
@media (max-width: 768px) {
  .legal-page {
    padding: 40px 24px;
  }

  .legal-header {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .legal-header h1 {
    font-size: 2rem;
  }

  .legal-content {
    padding: 0 15px;
    font-size: 0.95rem;
  }

  .terms-section,
  .privacy-section {
    padding: 25px 20px;
    margin-bottom: 25px;
  }

  .legal-text {
    gap: 20px;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  p {
    font-size: 0.95rem;
    margin-bottom: 16px;
  }
}

/* Small Mobile Devices */
@media (max-width: 480px) {
  .legal-page {
    padding: 30px 16px;
  }

  .legal-header {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .legal-header h1 {
    font-size: 1.75rem;
  }

  .legal-content {
    padding: 0 10px;
  }

  .terms-section,
  .privacy-section {
    padding: 20px 15px;
    margin-bottom: 20px;
    border-radius: 8px;
  }

  h2 {
    font-size: 22px;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 17px;
    margin-bottom: 8px;
  }

  p {
    font-size: 0.9rem;
    margin-bottom: 14px;
    line-height: 1.5;
  }

  .legal-text {
    gap: 16px;
  }
}
